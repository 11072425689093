import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MediaApiService {

  constructor(
    private _http: HttpClient
  ) { }

  uploadImage(type: string, image: any) {
    const formData = new FormData();
    formData.set('type', type);
    formData.set('image',  image);
    return this._http.post(environment.API_URL + '/media/upload', formData)
    .pipe(map((data) => ({
      path: data['path']
    })));
  }
}
