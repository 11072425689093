import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkArea } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class WorkAreaService {

  constructor(
    private _http: HttpClient,
  ) { }

  getWorkAreas(search?: string) {
    return this._http.get(environment.API_URL + `work_area/all`)
    .pipe(map((data: any) => ({
      workareas: (data.work_areas || []).map(workarea => new WorkArea().parse(workarea))
    })));
  }

  getWorkArea(workareaId: number) {
    return this._http.get(environment.API_URL + `work_area/` + workareaId )
      .pipe(map((data: any) => ({
        workarea: new WorkArea().parse(data['work_area'])
      })));
  }

  saveWorkArea(workarea: WorkArea) {
    let url = 'work_area/save';

    if (workarea.id !== 0) {
      url = url + '/' + workarea.id;
    }
    const params = {
      title: workarea.title,
      body: workarea.body,
      cover_path: workarea.coverPath,
      short_description: workarea.description,
      classification: workarea.classification,
      poblacion: workarea.poblacion,
      languages: workarea.languages.map(language => {
        return {
          id: language.id,
          body: language.body,
          classification: workarea.classification,

          title: language.title,
          language: language.language,
          short_description: language.shortDescription
       }; })
    };

    return this._http.post(environment.API_URL + url, params)
    .pipe(map((data) => ({
      workareas: data['work_areas'].map((workarea: WorkArea) => new WorkArea().parse(workarea))
    })));
  }

  deleteWorkArea(workareaId: number) {
    return this._http.delete(environment.API_URL + 'work_area/' + workareaId)
    .pipe(map((data) => ({
      workarea: true
    })));
  }
}
