import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Initiative } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class InitiativeService {

  constructor(
    private _http: HttpClient,
  ) { }

  getInitiatives(search?: string) {



    return this._http.get(environment.API_URL + `initiative/all` )
    .pipe(map((data: any) => ({
      initiatives: (data.initiatives || []).map(initiative => new Initiative().parse(initiative))
    })));
  }

  getInitiative(initiativeId: number) {
    return this._http.get(environment.API_URL + `initiative/` + initiativeId )
      .pipe(map((data: any) => ({
        initiative: new Initiative().parse(data['initiative'])
      })));
  }

  saveInitiative(initiative: Initiative) {

    console.log(initiative);
    let url = 'initiative/save';

    if (initiative.id !== 0) {
      url = url + '/' + initiative.id;
    }
    const params = {
      title: initiative.title,
      body: initiative.body,
      cover_path: initiative.coverPath,
      poblacion: initiative.poblacion,
      video_path: initiative.video_path,
      work_area: initiative.work_area,
      short_description: initiative.shortDescription,
      slider: initiative.slider,
      languages: initiative.languages.map(language => {
        return {
          id: language.id,
          body: language.body,
          title: language.title,
          language: language.language,
          short_description: language.shortDescription
       }; })
    };

    return this._http.post(environment.API_URL + url, params)
    .pipe(map((data) => ({
      initiatives: data['initiatives'].map((initiative: Initiative) => new Initiative().parse(initiative))
    })));
  }

  deleteInitiative(initiativeId: number) {
    return this._http.delete(environment.API_URL + 'initiative/' + initiativeId)
    .pipe(map((data) => ({
      initiative: true
    })));
  }
}
