import { App } from 'src/app/_core/models';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class AppApiService {

  constructor(
    private _http: HttpClient
  ) { }

  getApps(search?: string) {
    const params = [
      `search=${ search || '' }`,
    ].join('&');

    return this._http.get(environment.API_URL + `app/all?` + params)
      .pipe(map((data: any) => ({
        apps: data.apps.map( (app: App) => new App().parse(app))
      })));
  }

  deleteApp(id: number) {
    return this._http.delete(environment.API_URL + 'app/delete/' + id)
    .pipe(map((data) => ({
      deleted: true
    })));
  }

  getApp(id: number) {
    return this._http.get(environment.API_URL + `app/` + id )
      .pipe(map((data: any) => ({
        app: new App().parse(data['app'])
      })));
  }

  saveApp(app: App) {
    
    let url = 'app/save';

    if (app.id !== 0) {
      url = url + '/' + app.id;
    }
    const params = {
      label: app.label,
      description: app.description,
      order: app.order,
      icon_path: app.iconPath,
      cover_path: app.coverPath,
      video_path: app.video_path,

      partners: app.partners,
      slider: app.slider,
      languages: app.languages.map(language => {
        return {
          id: language.id,
          description: language.description,
          short_description: language.short_description,
          label: language.label,
          language: language.language,
       }; })
    };

    return this._http.post(environment.API_URL + url, params)
    .pipe(map((data) => ({
      apps: ''
    })));
  }
}
